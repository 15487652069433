@import "./utils/fonts.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

form {
  button {
    margin-top: 20px;
    width: auto;
  }
  p {
    color: #ffca00 !important;
    font-weight: bold !important;
  }
}

input,
textarea {
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 5px;
}

label,
textarea,
input {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

label {
  color: #fff;
  text-transform: capitalize;
}

input,
textarea {
  color: #563913;
}

a {
  text-decoration: none !important;
}

a,
button {
  font-family: "Caudex", sans-serif;
}

p {
  margin: 0;
}
