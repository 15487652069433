@font-face {
  font-family: "Caudex";
  src: local("fonts"), url(./fonts/Caudex-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Charm";
  src: local("fonts"), url(./fonts/Charm-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "GreatVibes";
  src: local("fonts"), url(./fonts/GreatVibes-Regular.ttf) format("opentype");
}
